@charset "UTF-8";

@import "./colors.scss";
@import "./fonts.scss";
@import "./typography.scss";

@layer primeng {
  body .p-button {
    margin: 0;
    color: var(--white);
    background-color: var(--purple-10);
    border: 1px solid var(--purple-10);
    padding: 0.4rem 1rem;
    border-radius: 0.5rem;

    &:not(:disabled):hover {
      background-color: var(--purple-09);
      color: var(--white);
      border: 1px solid var(--purple-09);
    }

    &:focus {
      box-shadow: none;
    }

    &:enabled:focus {
      box-shadow: none;
    }

    &.p-disabled {
      background-color: var(--grey-03);
      border: 1px solid var(--grey-03);
      opacity: 1;
    }

    &.p-button-outlined {
      background-color: var(--white);
      color: var(--accent-color);
      border: 1px solid var(--accent-color);
      border-radius: 0.5rem;

      &:not(:disabled):hover {
        opacity: 0.8;
      }

      &.p-disabled {
        opacity: 0.5;
      }
    }

    &.p-button-primary-navy {
      background-color: var(--purple-10);
      color: var(--white);
      border: 1px solid var(--purple-10);

      &:not(:disabled):hover {
        background-color: var(--purple-09);
        color: var(--white);
        border: 1px solid var(--purple-09);
      }

      &.p-disabled {
        background-color: var(--grey-03);
        border: 1px solid var(--grey-03);
        opacity: 1;
      }
    }

    &.p-button-primary-white {
      background-color: var(--white);
      color: var(--accent-color);
      border: 1px solid var(--white);

      &:not(:disabled):hover {
        background-color: var(--white);
        color: var(--accent-color);
        border: 1px solid var(--white);
        opacity: 0.8;
      }

      &.p-disabled {
        background-color: var(--white);
        color: var(--accent-color);
        border: 1px solid var(--white);
        opacity: 0.5;
      }
    }

    &.p-button-secondary-navy {
      background-color: transparent;
      color: var(--purple-10);
      border: 1px solid var(--purple-10);

      &:not(:disabled):hover {
        background-color: var(--purple-01);
        color: var(--purple-10);
        border: 1px solid var(--purple-10);
      }

      &.p-disabled {
        background-color: transparent;
        border: 1px solid var(--grey-05);
        color: var(--grey-05);
        opacity: 1;
      }
    }

    &.p-button-secondary-white {
      background-color: transparent;
      color: var(--white);
      border: 1px solid var(--white);

      &:not(:disabled):hover {
        background-color: transparent;
        color: var(--white);
        border: 1px solid var(--white);
        opacity: 0.8;
      }

      &.p-disabled {
        background-color: transparent;
        color: var(--white);
        border: 1px solid var(--white);
        opacity: 0.5;
      }
    }

    &.p-button-link {
      color: var(--accent-color);
      background: transparent;
      border: transparent;
      padding: 0;

      &:not(:disabled):hover {
        background: transparent;
        border: transparent;
        color: var(--purple-07);
      }

      &:not(:disabled):focus {
        box-shadow: none;
        background: transparent;
        border: transparent;
        color: var(--purple-07);
      }

      &.p-disabled {
        background-color: transparent;
        color: var(--grey-05);
      }
    }
  }

  body .p-button-label {
    font-weight: inherit;
  }

  body .p-button-label {
    font-weight: inherit;
  }

  body .p-dialog {
    border-radius: 1.25rem;
    border: 1px solid var(--grey-02);
    background: var(--white);

    .p-dialog-content:last-of-type {
      border-radius: 1.25rem;
    }

    .p-dialog-header {
      background: var(--white);
      color: var(--dark-navy);
      border-top-right-radius: 1.25rem;
      border-top-left-radius: 1.25rem;
      padding: 1.25rem;

      @media (min-width: 640px) {
        padding: 2.5rem 3.75rem 2rem 3.75rem;
      }

      .p-dialog-title {
        @extend h4;
      }
    }

    .p-dialog-content {
      padding: 0 1.25rem 1.5rem 1.25rem;

      @media (min-width: 640px) {
        padding: 0 3.75rem 2.5rem 3.75rem;
      }
    }

    .p-dialog-header-icons {
      position: absolute;
      top: 0.75rem;
      right: 1rem;

      @media (min-width: 640px) {
        top: 2rem;
        right: 2rem;
      }
    }
  }

  body .p-inputtext {
    @extend .body-2-regular;

    padding: 0.6875rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid var(--grey-02);
    background: var(--grey-01);
    height: 2.5rem;

    &:enabled:hover {
      border-color: var(--grey-02);
    }

    &:enabled:focus {
      border-color: var(--grey-02);
      box-shadow: none;
    }

    &.ng-dirty.ng-invalid {
      border-color: var(--grey-02);
    }

    &.ng-invalid.ng-touched {
      border-color: var(--red-07);

      &:enabled:hover {
        border-color: var(--red-07);
      }

      &:enabled:focus {
        border-color: var(--red-07);
        box-shadow: none;
      }
    }
  }

  body .p-radiobutton {
    width: 1rem;
    height: 1rem;

    .p-radiobutton-box {
      border: 1px solid var(--grey-02);
      background: var(--white);
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s;

      &.p-highlight {
        border-color: var(--accent-color);
        background: var(--accent-color);
      }

      .p-radiobutton-icon {
        width: 6px;
        height: 6px;
        transition-duration: 0.2s;
        background-color: var(--white);
      }
    }
  }

  body .p-radiobutton-label {
    @extend .body-2-regular;
    color: var(--dark-navy);
    text-wrap: nowrap;
  }

  body .p-card {
    box-shadow: none;
    border-radius: 1.25rem;

    .p-card-body {
      padding: 2rem 1.25rem 1.75rem 1.25rem;

      @media (min-width: 640px) {
        padding: 2.5rem 1.5rem 1.75rem 1.5rem;
      }
    }

    .p-card-title {
      @extend h5;
      color: var(--dark-navy);
      margin-bottom: 0;
    }

    .p-card-content {
      padding: 1rem 0 0 0;

      @media (min-width: 640px) {
        padding: 1.25rem 0 0 0;
      }
    }
  }

  body .p-tabview-title {
    @extend .body-2-semibold;
  }

  body .p-component-overlay {
    backdrop-filter: blur(5px);
  }

  body .p-inputgroup {
    border-radius: 0.5rem;

    .p-inputtext {
      border-right: 0;

      &.ng-invalid.ng-touched ~ .p-inputgroup-addon {
        border-color: var(--red-07);
      }
    }

    .p-inputgroup-addon {
      background: var(--grey-01);
      padding: 0.25rem;
      border: 1px solid var(--grey-02);
      min-width: 0;
      border-left-width: 0;

      .p-button {
        padding: 0.25rem;
        border-radius: 0.25rem;
      }
    }
  }
}
