:root {
  // purple-primary color palettes
  --purple-01: #eef0ff;
  --purple-02: #cbd2ff;
  --purple-03: #bac3fe;
  --purple-04: #a8b4fe;
  --purple-05: #7587fe;
  --purple-06: #5269fd;
  --purple-07: #3d55ee;
  --purple-08: #2139d7;
  --purple-09: #162cb7;
  --purple-10: #02116d;

  // green color palettes
  --green-01: #ebfaf4;
  --green-02: #c4f0dd;
  --green-03: #9ce5c7;
  --green-04: #61d6a5;
  --green-05: #39cc8e;
  --green-06: #1cb977;
  --green-07: #10a265;
  --green-08: #098c55;
  --green-09: #067647;
  --green-10: #025d37;

  // red color palettes
  --red-01: #fae5ed;
  --red-02: #f3bfd2;
  --red-03: #ea8cae;
  --red-04: #e36693;
  --red-05: #de4c81;
  --red-06: #d72666;
  --red-07: #d0004b;
  --red-08: #a8003d;
  --red-09: #860030;
  --red-10: #630024;

  // yellow color palettes
  --yellow-01: #fffce5;
  --yellow-02: #fff9c0;
  --yellow-03: #fff6a6;
  --yellow-04: #fff281;
  --yellow-05: #fceb5b;
  --yellow-06: #f5e236;
  --yellow-07: #f0d800;
  --yellow-08: #ccb807;
  --yellow-09: #ab9b08;
  --yellow-10: #736807;

  // lilac color palettes
  --lilac-01: #f9f0ff;
  --lilac-02: #efdbff;
  --lilac-03: #d3adf7;
  --lilac-04: #b37feb;
  --lilac-05: #9254de;
  --lilac-06: #722ed1;
  --lilac-07: #531dab;
  --lilac-08: #391085;
  --lilac-09: #22075e;
  --lilac-10: #120338;

  // pink color palettes
  --pink-01: #fff4fc;
  --pink-02: #ffe2f6;
  --pink-03: #ffd1f0;
  --pink-04: #ffb8e8;
  --pink-05: #ff93dc;
  --pink-06: #f476cb;
  --pink-07: #e759b8;
  --pink-08: #c950a2;
  --pink-09: #b14b8f;
  --pink-10: #903e75;

  //neutral color palettes
  --white: #fff;
  --grey-01: #f9f9f9;
  --grey-02: #cdced9;
  --grey-03: #b3b5c6;
  --grey-04: #9a9cb3;
  --grey-05: #8088a2;
  --grey-06: #686b8e;
  --grey-07: #4f527b;
  --grey-08: #1c2155;
  --grey-09: #d2d5df;
  --navy: #030842;
  --dark-navy: #001046;
  --light-cyan: #5dc2e4;
  --light-blue: #b0c5f5;

  // foundation color palettes
  --accent-color: var(--purple-06);
  --negative-color: var(--red-07);
  --warning-color: var(--yellow-07);
  --positive-color: var(--green-05);

  --section-grey-color: #f3f3fb;
}
