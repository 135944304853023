html {
  font-family: "Almarena Neue", "Inter-Regular", "Inter-Semibold";
}

h1 {
  font-family: "Almarena Neue";
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
}

h2 {
  font-family: "Almarena Neue";
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

h3 {
  font-family: "Almarena Neue";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem;
}

h4 {
  font-family: "Almarena Neue";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
}

h5 {
  font-family: "Almarena Neue";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
}

.header-button-semibold {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.025rem;
}

.button-semibold {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.03rem;
}

.body-1-regular {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.body-1-semibold {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.body-2-regular {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.body-2-semibold {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.body-3-regular {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.body-3-semibold {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

@media (min-width: 640px) {
  h1 {
    font-size: 4rem;
    line-height: 3.625rem;
  }
  
  h2 {
    font-size: 3.25rem;
    line-height: 3.25rem;
  }
  
  h3 {
    font-size: 2.625rem;
    line-height: 2.875rem;
  }
  
  h4 {
    font-size: 2rem;
    line-height: 2.125rem;
  }
  
  h5 {
    font-size: 1.375rem;
    line-height: 1.5rem; 
  }
  
  .body-1-regular {
    font-size: 1.125rem;
  }
  
  .body-1-semibold {
    font-size: 1.125rem;
  }
  
  .body-2-regular {
    font-size: 0.875rem;
    font-weight: 400;
  }
  
  .body-2-semibold {
    font-size: 0.875rem;
  }

  .header-button-semibold {
    font-size: 1rem;
    letter-spacing: 0.03rem;
  }
}
