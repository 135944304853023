@import "styles/layers.css";

@layer tailwind-base {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;

@import "node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "node_modules/primeng/resources/primeng.min.css";

@import "node_modules/material-symbols/index.css";

@import "styles/primeng-overrides.scss";
